import Flickity from "react-flickity-component"
import Image from "../../components/image/image"
import Layout from "../../components/layout"
import PrevNext from "../../components/prev-next/prev-next"
import ProjectFacts from "../../components/project-facts/project-facts"
import React from "react"
import Section from "../../components/section/section"
import Seo from "../../components/seo"
import Stage from "../../components/stage/stage"
import analyticsReviewImg from "../../images/projects/deputy-gamification/analytics-review.png"
import gameImg1 from "../../images/projects/deputy-gamification/game-1.png"
import gameImg2 from "../../images/projects/deputy-gamification/game-2.png"
import gameImg3 from "../../images/projects/deputy-gamification/game-3.png"
import gameImg4 from "../../images/projects/deputy-gamification/game-4.png"
import gameImg5 from "../../images/projects/deputy-gamification/game-5.png"
import gameImg6 from "../../images/projects/deputy-gamification/game-6.png"
import gameImg7 from "../../images/projects/deputy-gamification/game-7.png"
import gameImg8 from "../../images/projects/deputy-gamification/game-8.png"
import gameSequenceImg from "../../images/projects/deputy-gamification/game-sequence.png"
import introImg from "../../images/projects/deputy-gamification/intro.png"

const DeputyGamification = () => (
  <Layout>
    <Seo title="Gamification redesign" />

    <Section>
      <h1>Gamification redesign</h1>
      <p>
        Deputy is a staff rostering software that helps manage schedules from
        any device. To accelerate user learning and facilitate engagement with
        the product, particulary during a free product trial, the company uses
        gamification.
      </p>

      <ProjectFacts
        className="mb-4 border-top"
        name="Deputy"
        type="UX/UI Design"
        year="2019"
      />

      <Image img={introImg} />

      <div className="row">
        <div className="col-12 col-md-6 border-right pr-md-5">
          <h2 className="mb-4">Business goal</h2>

          <p>
            Many products offer an initial free trail, but we offer our users
            the ability to unlock more free days which brings them some sense of
            achievement and entertainment.
          </p>
          <p>
            The main goal for this project was to increase current user
            engagement with the product through gamification games.
          </p>
        </div>
        <div className="col-12 col-md-6 pl-md-5">
          <h2 className="mb-4">My role</h2>

          <p>
            I was responsible for reviewing and proposing improvements to
            current user experience, as well as user interface, following the
            company brand guidelines.
          </p>
        </div>
      </div>
    </Section>

    <Section heading="Solution" background={true}>
      <p>The following changes were introduced:</p>
      <ul>
        <li>
          In our copy review, users now have a clear explanation of how certain
          tasks benefit them
        </li>
        <li>
          We replaced any outdated videos with cutting-edge, clear
          illustrations, and the singular video we now provide, gives users
          simple information to process more easily
        </li>
        <li>
          Plus the combination of 2 games into 1 ( mobile phone number and app),
          offers them a more beneficial experience
        </li>
      </ul>
    </Section>

    <Section heading="What did the process look like?">
      <div className="d-flex justify-content-between flex-column flex-sm-row mb-5">
        <Stage stage="Design">
          <ul className="text-left">
            <li>Data analysis</li>
            <li>User Interface</li>
            <li>User feedback</li>
          </ul>
        </Stage>
        <Stage stage="Build">
          <ul className="text-left">
            <li>Front &amp; Back End</li>
            <li>A/B test set up</li>
          </ul>
        </Stage>
        <Stage stage="Release &amp; Learn">
          <ul className="text-left">
            <li>Data insights</li>
            <li>User feedback</li>
          </ul>
        </Stage>
      </div>

      <h3>Data analytics review</h3>
      <p className="mb-5">
        Before jumping into design mode and crafting new experiences, I watched
        a number of FulStory recordings and went through some data, to uncover
        opportunities and prioritise improvements.
      </p>

      <Image
        img={analyticsReviewImg}
        caption="User engagement with current gamification  01/01/18 - 15/07/18"
      />
    </Section>

    <Section background={true}>
      <h3>UI Design</h3>
      <p className="mb-5">
        Looking at the data and talking to customers made it clear, that a lot
        of people were overwhelmed and not sure what they should focus on.
      </p>
      <Flickity
        className={"image-carousel image-carousel-big"} // default ''
        elementType={"div"} // default 'div'
        options={{
          initialIndex: 0,
        }}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <img src={gameImg1} alt="UI Design"/>
        <img src={gameImg2} alt="UI Design"/>
        <img src={gameImg3} alt="UI Design"/>
        <img src={gameImg4} alt="UI Design"/>
        <img src={gameImg5} alt="UI Design"/>
        <img src={gameImg6} alt="UI Design"/>
        <img src={gameImg7} alt="UI Design"/>
        <img src={gameImg8} alt="UI Design"/>
      </Flickity>
    </Section>

    <Section>
      <h3>Game sequence challenge</h3>
      <p>
        A small challenge I faced was when, in the new design, I proposed to
        change the game sequence. Some people thought, ‘Book a demo with an
        expert’ should be the first contact with us, not the last. I personally
        believed that potential users should first explore the basics
        themselves, so they can dive deeper with a Deputy expert later, as
        booking a demo is more an extention of an introduction to the product
        where users can get any product clarification they need.
      </p>

      <Image
        img={gameSequenceImg}
        caption="‘Book a time with an expert’ game placement"
      />

      <p>
        The agreement was made to find out in reality, rather than theory and
        learn from our users what works best.
      </p>
      <p>
        So, we offered 50% of new trial users, ‘Book a demo with an expert’
        first, and the other 50%, ‘Book a demo with an expert’ last.
      </p>
      <br />
      <br />
      <h3>Outcomes</h3>
      <p>
        The new design created an attachment to the product which translated
        into loyalty to our subscriptions and an increase to self and overall
        conversion rates.
      </p>
      <p>
        It also helped us, as a team, to gain a deeper understanding of how
        users explore and prefer to interact with the product.
      </p>

      <PrevNext
        prev="/project/deputy-growth-experiments"
        next="/project/tournament-app"
      />
    </Section>
  </Layout>
)

export default DeputyGamification
